import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gis from "/src/components/img/gis"
import ImgCon from "/src/components/img-con"
import BtnWrap from "/src/components/btn-wrap"
import SEO from "/src/components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Měřítko na mapě" />
    <h1>Měřítko na mapě</h1>

    <p><strong>Měř&iacute;tko mapy</strong>&nbsp;(tj. d&eacute;lkov&eacute; měř&iacute;tko) vyjadřuje poměr vzd&aacute;lenosti na nezkreslen&eacute; mapě a vzd&aacute;lenosti skutečn&eacute;. Kvůli zakřiven&iacute; Země a zvolen&eacute;mu zobrazen&iacute; se v&scaron;ak na v&scaron;ech map&aacute;ch jist&eacute; zkreslen&iacute; projevuje, proto složitěj&scaron;&iacute; definice hovoř&iacute;, že jde o poměr zmen&scaron;en&iacute; d&eacute;lek na mapě k d&eacute;lce ve skutečnosti.</p>
    <p>Je jedn&iacute;m ze&nbsp;<Link to="/gis/mapova-kompozice/">z&aacute;kladn&iacute;ch kompozičn&iacute;ch prvků mapy</Link>&nbsp;a na mapě by tak za běžných okolností nemělo chybět &ndash; pod&iacute;l&iacute; se totiž na přesn&eacute;m sdělen&iacute; prostorov&yacute;ch informac&iacute; na mapě.</p>
    <p>Je podř&iacute;zeno &uacute;čelu a tematick&eacute;mu zaměřen&iacute; kartografick&eacute;ho d&iacute;la. M&aacute; v&yacute;znamn&yacute; vliv na podrobnost a přesnost zn&aacute;zorněn&yacute;ch jevů, možnosti ře&scaron;en&iacute; různ&yacute;ch &uacute;loh na dan&eacute;m d&iacute;lu apod. Volba měř&iacute;tka je zpravidla ovlivněna v&yacute;znamem a unik&aacute;tn&iacute;mi vlastnostmi zn&aacute;zorňovan&eacute;ho &uacute;zem&iacute;, zvolen&yacute;m form&aacute;tem kartografick&eacute;ho d&iacute;la a tak&eacute; přehlednost&iacute; i čitelnost&iacute; v&yacute;sledn&eacute; mapy.</p>
    <hr /><h2 id="druhy">Způsob vyj&aacute;dřen&iacute; měř&iacute;tka</h2>
    <p>Z hlediska v&yacute;sledn&eacute; prezentace rozli&scaron;ujeme&nbsp;<strong>3 z&aacute;kladn&iacute; druhy měř&iacute;tka: </strong></p>
    <ul>
    <li><strong>č&iacute;seln&eacute;, </strong></li>
    <li><strong>grafick&eacute;</strong></li>
    <li><strong>a slovn&iacute;</strong>.</li>
    </ul>
    <p>Velmi běžn&aacute; a praktick&aacute; je kombinace č&iacute;seln&eacute;ho a grafick&eacute;ho měř&iacute;tka. Grafick&eacute; měř&iacute;tko samotn&eacute; m&aacute; v&scaron;ak při reprodukci z&aacute;sadn&iacute; v&yacute;hodu &ndash; při př&iacute;padn&eacute;m zvět&scaron;en&iacute;/zmen&scaron;en&iacute; narozd&iacute;l od č&iacute;seln&eacute;ho zachov&aacute;v&aacute; svůj poměr.</p>
    <h3>Č&iacute;seln&eacute; měř&iacute;ko</h3>
    <p><strong>Měř&iacute;tko č&iacute;seln&eacute;</strong>&nbsp;je matematick&eacute; vyj&aacute;dřen&iacute; poměru&nbsp;ve tvaru <em>mapa : realita</em>, v tomto pořad&iacute;. V naprost&eacute; vět&scaron;ině př&iacute;padů vyjadřuje, kolika centimetrům ve skutečnosti odpov&iacute;d&aacute; jeden centimetr na nezkreslen&eacute; mapě. Na mapě s měř&iacute;tkem<em>&nbsp;<strong>1 : 10 000</strong>&nbsp;</em>tak 1 cm odpov&iacute;d&aacute; 10 000 cm ve skutečnosti, tzn. 1 cm na mapě = 100 m ve skutečnosti.</p>
    <h3 id="graficke">Grafick&eacute; měř&iacute;tko</h3>
    <p><strong>Měř&iacute;tko grafick&eacute;</strong>&nbsp;je vizualizac&iacute; poměru pomoc&iacute; &uacute;seček, jež tvoř&iacute; referenčn&iacute; vzd&aacute;lenosti na mapě (typicky např. 100 m, 250 m, 1 km, 75 km &hellip;). V př&iacute;padě map velmi mal&yacute;ch měř&iacute;tek se již znatelně projevuje zkreslen&iacute; způsoben&eacute; zakřiven&iacute;m Země a zvolen&yacute;m kartografick&yacute;m zobrazen&iacute;m &ndash; v takov&eacute;m př&iacute;padě odpov&iacute;d&aacute; stejn&eacute; vzd&aacute;lenosti na různ&yacute;ch m&iacute;stech mapy různ&aacute; vzd&aacute;lenost ve skutečnosti. Pak b&yacute;v&aacute; grafick&eacute; měř&iacute;tko rozděleno na několik d&iacute;lč&iacute;ch č&aacute;st&iacute; &ndash; v př&iacute;padě Mercatorova zobrazen&iacute; podle zeměpisn&eacute; &scaron;&iacute;řky. Častěji je tento probl&eacute;m ře&scaron;en t&iacute;m, že se měř&iacute;tko vztahuje ke středu mapov&eacute;ho pole, pak jde o tzv.&nbsp;<strong>středn&iacute; měř&iacute;tko</strong>.</p>
    <h3>Slovn&iacute; měř&iacute;tko</h3>
    <p><strong>Měř&iacute;tko slovn&iacute;</strong>&nbsp;vyjadřuje poměr pomoc&iacute; srozumiteln&eacute;ho textu, jehož zněn&iacute; může b&yacute;t n&aacute;sleduj&iacute;c&iacute;:&nbsp;<em>&bdquo;1 cm na mapě odpov&iacute;d&aacute; 100 m ve skutečnosti&ldquo;</em>. Objevuje se předev&scaron;&iacute;m na star&yacute;ch map&aacute;ch a v současnosti na někter&yacute;ch turistick&yacute;ch.</p>
    <hr /><h2 id="velikost">Velikost měř&iacute;tka</h2>
    <p>Z pohledu podrobnosti měř&iacute;tka pak rozli&scaron;ujeme tak&eacute; 3 z&aacute;kladn&iacute; skupiny &ndash; mapy velk&eacute;ho (velk&aacute; podrobnost), středn&iacute;ho a mal&eacute;ho měř&iacute;tka (mal&aacute; podrobnost). Toto je v&scaron;ak relativn&iacute; rozdělen&iacute;, kter&eacute; z&aacute;lež&iacute; na tom, za jak&yacute;m &uacute;čelem je mapa sestavov&aacute;na. V česk&eacute;m prostřed&iacute; rozli&scaron;ujeme 2 hlavn&iacute; pohledy na dělen&iacute; dle velikosti měř&iacute;tka &ndash; pohled geografů a geodetů, kteř&iacute; potřebuj&iacute; pracovat ve vět&scaron;ině př&iacute;padů s mnohem vy&scaron;&scaron;&iacute; podrobnost&iacute; a přesnost&iacute;.</p>

    <table>
    <tbody>
    <tr>
    <th>Mapy</th>
    <th>Geografick&yacute; pohled</th>
    <th>Geodetick&yacute; pohled</th>
    </tr>
    <tr>
    <td>Velk&eacute;ho měř&iacute;tka &ndash; největ&scaron;&iacute; &bdquo;přibl&iacute;žen&iacute;&ldquo;</td>
    <td>&nbsp;&gt; 1 : 200 000</td>
    <td>&gt; 1 : 5 000</td>
    </tr>
    <tr>
    <td>Středn&iacute;ho měř&iacute;tka</td>
    <td>1 : 200 000 &ndash; 1 : 1 000 000</td>
    <td>1 : 5 000 &ndash; 1 : 200 000</td>
    </tr>
    <tr>
    <td>Mal&eacute;ho měř&iacute;tka &ndash; nejmen&scaron;&iacute; &bdquo;přibl&iacute;žen&iacute;&ldquo;</td>
    <td>&lt; 1 : 1 000 000</td>
    <td>&lt; 1 : 200 000</td>
    </tr>
    </tbody>
    </table>

    <hr /><h2 id="zasady">Kartografick&eacute; z&aacute;sady při tvorbě měř&iacute;tka</h2>
    <p>Při vkl&aacute;d&aacute;n&iacute; měř&iacute;tka do v&yacute;sledn&eacute; kompozice bychom měli m&iacute;t na paměti <strong>obecn&eacute; pravidlo</strong>, aby bylo co nejjednodu&scaron;&scaron;&iacute; a pro uživatele tak lehce čiteln&eacute;. Ve v&yacute;sledn&eacute;&nbsp;<Link to="/gis/mapova-kompozice/">kompozici</Link>&nbsp;jde o velice podřadn&yacute; prvek, kter&yacute; nezatěžuje čten&aacute;ře&nbsp;a neodv&aacute;d&iacute; jeho pozornost od samotn&eacute;ho mapov&eacute;ho pole.</p>
    <h3>D&eacute;lka grafick&eacute;ho měř&iacute;tka</h3>
    <p>D&eacute;lka &uacute;sečky grafick&eacute;ho měř&iacute;tka odpov&iacute;d&aacute; n&aacute;sobku tzv.&nbsp;<strong>hlavn&iacute;ho dělen&iacute;</strong>&nbsp;(ide&aacute;lně dvojn&aacute;sobek, pětin&aacute;sobek, či desetin&aacute;sobek). Toto dělen&iacute; je vyznačeno na &uacute;sečce k&oacute;tov&aacute;n&iacute;m &ndash; značkou, nebo změnou struktury &uacute;sečky (např. stř&iacute;d&aacute;n&iacute; b&iacute;l&eacute; a čern&eacute; na měř&iacute;tku).</p>
    <h3>Vedlej&scaron;&iacute; dělen&iacute; grafick&eacute;ho měř&iacute;tka</h3>
    <p><strong>Vedlej&scaron;&iacute; dělen&iacute;</strong>&nbsp;d&aacute;le podrobněji rozčleňuje stejn&yacute;m způsobem hlavn&iacute; dělen&iacute; na men&scaron;&iacute; segmenty &ndash; obvykle m&eacute;ně v&yacute;razně. Na &uacute;sečce ho můžeme dle na&scaron;eho uv&aacute;žen&iacute; uv&eacute;st jen v poč&aacute;tečn&iacute;m, lev&eacute;m segmentu hlavn&iacute;ho dělen&iacute;.</p>
    <h3>V Brně popisujte ve&scaron;ker&eacute; k&oacute;tov&aacute;n&iacute;</h3>
    <p><strong>Ve&scaron;ker&eacute; k&oacute;tov&aacute;n&iacute; se popisuje</strong>&nbsp;př&iacute;slu&scaron;nou hodnotou, kterou reprezentuje v realitě. Toto je v&scaron;ak praxe, kter&aacute; je vyučov&aacute;na na&nbsp;<a href="http://geogr.muni.cz/" target="_blank" rel="noreferrer noopener">brněnsk&eacute; geografii</a>. Pro jin&eacute; kartografick&eacute; &scaron;koly plat&iacute; různ&eacute; zvyklosti a ty vět&scaron;inou připou&scaron;t&iacute; vedlej&scaron;&iacute; dělen&iacute; nepopisovat.</p>
    <h3>Nezapomenout na d&eacute;lkovou jednotku</h3>
    <p>Za posledn&iacute; hodnotu popisu nesm&iacute;me zapomenout&nbsp;<strong>přidat př&iacute;slu&scaron;nou jednotku hodnot&nbsp;</strong>(m, km ..). Ta by měla b&yacute;t vyvedena stejn&yacute;m způsobem jako hodnoty k&oacute;tov&aacute;n&iacute;, na stejn&eacute;m ř&aacute;dku, ve zkratce a ve stejn&eacute;m jazyce, v n&iacute;mž je mapa vyvedena.</p>
    <h3>Jak kombinovat grafick&eacute; a č&iacute;seln&eacute; měř&iacute;tko</h3>
    <p>Pokud použijeme ke grafick&eacute;mu měř&iacute;tku i to č&iacute;seln&eacute;, měli bychom ho uv&eacute;st na opačnou stranu &uacute;sečky než popis k&oacute;tov&aacute;n&iacute;. Font&nbsp;<strong>č&iacute;seln&eacute;ho měř&iacute;tka</strong>&nbsp;by měl b&yacute;t rovněž asi dvakr&aacute;t vět&scaron;&iacute; než tento popis. Mezeru p&iacute;&scaron;eme z obou stran dvojtečky, mezi trojici cifer v měř&iacute;tkov&eacute;m č&iacute;sle a nepouž&iacute;v&aacute;me slovn&iacute; zkr&aacute;cen&iacute; (např. 100 tis. a 5 mil.).</p>
    <h3>Slovn&iacute; měř&iacute;tko obvykle nepřid&aacute;vejte</h3>
    <p><strong>Slovn&iacute; měř&iacute;tko</strong><strong>nen&iacute;</strong><strong>obvykl&eacute; přid&aacute;vat</strong>, když ho z nějak&eacute;ho důvodu chceme um&iacute;stit, tak by velikost jeho fontu měla b&yacute;t, narozd&iacute;l od č&iacute;seln&eacute;ho, stejně velik&aacute;, jako popis k&oacute;tov&aacute;n&iacute;. Při jeho sestaven&iacute; je třeba db&aacute;t na logickou a gramatickou spr&aacute;vnost. Použijte rovněž co nejjednodu&scaron;&scaron;&iacute; bezpatkov&yacute; font, např. Arial.</p>
    <h3>Měř&iacute;tko nenadepisovat a nezdobit</h3>
    <p><strong>Měř&iacute;tko nepopisujeme slovem &bdquo;Měř&iacute;tko&ldquo;</strong>&nbsp;či p&iacute;smenem&nbsp;<strong>&bdquo;M&ldquo;</strong>. Tato kartografick&aacute; z&aacute;sada je uplatňov&aacute;na, protože&nbsp;jde o nadbytečn&yacute; prvek, kter&yacute; zbytečně zatěžuje v&yacute;slednou kompozici. Odpusťte si i zdobn&eacute; měř&iacute;tka a fonty.</p>
    <hr /><h2 id="srovnavaci-hodnotove">Jin&eacute; druhy měř&iacute;tka</h2>
    <p>V mapov&eacute; kompozici se mohou vyskytnout kromě v&yacute;&scaron;e popisovan&eacute;ho d&eacute;lkov&eacute;ho měř&iacute;tka, kter&eacute; by mělo b&yacute;t um&iacute;stěno vždy a kter&eacute; vyjadřuje z&aacute;kladn&iacute; poměrov&eacute; informace, tak&eacute;:</p>
    <ul>
    <li><strong>srovn&aacute;vac&iacute;</strong></li>
    <li><strong>a hodnotov&eacute; měř&iacute;tko</strong>.</li>
    </ul>
    <h3>Srovn&aacute;vac&iacute; měř&iacute;tko</h3>
    <p><strong>Srovn&aacute;vac&iacute; měř&iacute;tko</strong>&nbsp;je referenc&iacute; na nějak&yacute; objekt, u kter&eacute;ho předpokl&aacute;d&aacute;me, že je pro uživatele mapy dobře zn&aacute;m&yacute;. Př&iacute;kladem může b&yacute;t při zobrazen&iacute; &uacute;zem&iacute; Antarktidy vykreslen&iacute; obrysů Česk&eacute; republiky, aby měl uživatel představu o jej&iacute; velikosti.</p>
    <h3>Hodnotov&eacute; měř&iacute;tko</h3>
    <p><strong>Hodnotov&eacute; měř&iacute;tko</strong>&nbsp;je prvek&nbsp;<Link to="/gis/mapova-kompozice/legenda/">legendy</Link>&nbsp;a slouž&iacute; pro odměřen&iacute; hodnoty symbolu podle jeho velikosti. Může m&iacute;t intervalovou (dan&aacute; velikost pro určit&yacute; interval hodnot) i funkčn&iacute; podobu (velikost dle vzorce, line&aacute;rn&iacute; či neline&aacute;rn&iacute;).</p>


    <hr />
    <ImgCon><Gis /><div>
    <h2>Studijn&iacute; materi&aacute;ly GIS, geoinformatika a&nbsp;kartografie</h2>
    <p>T&eacute;ma <strong>Měřítko na mapě</strong> je souč&aacute;st&iacute; <Link to="/gis/">studijn&iacute;ch materi&aacute;lů GIS, geoinformatika a kartografie</Link> vytvořených zejména na podkladě předmětů vyučovaných na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat, případně na téma předch&aacute;zej&iacute;c&iacute; či n&aacute;sleduj&iacute;c&iacute;.</p>
    <BtnWrap>
    <Link to="/gis/"><button>Rozcestník GIS</button></Link>
    <Link to="/gis/mapova-kompozice/"><button className="inv">&larr; Mapová kompozice</button></Link>
    <Link to="/gis/mapova-kompozice/legenda/"><button className="inv">Legenda mapy &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />
  </Layout>
)
